import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPaperPlane,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState, useContext } from "react";
import { getAuth, signOut } from "firebase/auth";
import { doc, onSnapshot, getFirestore, updateDoc } from "firebase/firestore";
import { AppContext } from "../App";
import { v4 as uuidv4 } from "uuid";
import date from "date-and-time";
import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export default function Messaging() {
  const [selectedChat, setSelectedChat] = useState(0);
  const [sortedMessages, setSortedMessages] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [message, setMessage] = useState();

  const { app, authedUser } = useContext(AppContext);
  const db = getFirestore(app);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", authedUser.email), (doc) => {
      setMessageData(doc.data());
    });
  }, []);

  useEffect(() => {
    if (!messageData) return;

    let newMessages = messageData.employees[selectedChat].messages.sort(
      function (a, b) {
        return new Date(b.date) - new Date(a.date);
      }
    );

    newMessages.reverse();

    let finalObj = {};
    newMessages.forEach((messages) => {
      const date = messages.date.split("T")[0];
      if (finalObj[date]) {
        finalObj[date].push(messages);
      } else {
        finalObj[date] = [messages];
      }
    });

    let newObj = [];
    for (const prop in finalObj) {
      const todayDate = new Date().toJSON().slice(0, 10);
      const messageDate = new Date(prop).toJSON().slice(0, 10);

      let date = messageDate;

      if (todayDate === messageDate) {
        date = "Today";
      } else {
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        date = new Date(prop).toLocaleDateString("en-GB", options);
      }

      newObj.push({ date: date, messages: finalObj[prop], id: uuidv4() });
    }

    setSortedMessages(newObj);
  }, [selectedChat, messageData]);

  const getLastMessageProperty = (data, prop) => {
    const messages = data.messages.reverse();
    return messages[0][prop];
  };

  const formatDateIntoTime = (messageDate) => {
    const originalDate = new Date(messageDate);
    return date.format(originalDate, "HH:mm");
  };

  const handleLogOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  async function getResponse(message) {
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: message,
      temperature: 0.7,
      n: 1,
      max_tokens: 256,
    });

    let jsonResponse = JSON.parse(response.request.response);

    const now = new Date();
    const time = date.format(now, "YYYY-MM-DDTHH:mm:ss");
    let currentMessageData = messageData;
    currentMessageData.employees[selectedChat].messages.push({
      message: jsonResponse.choices[0].text,
      id: uuidv4(),
      type: "notLoggedInUser",
      date: time,
    });

    const userRef = doc(db, "users", authedUser.email);
    await updateDoc(userRef, currentMessageData);
    setShowLoadingMessage(false);
  }

  async function submitMessage() {
    const now = new Date();
    const time = date.format(now, "YYYY-MM-DDTHH:mm:ss");

    let currentMessageData = messageData;
    currentMessageData.employees[selectedChat].messages.push({
      message,
      id: uuidv4(),
      type: "loggedInUser",
      date: time,
    });

    let currentMessage = message;

    setMessage("");

    setTimeout(() => {
      setShowLoadingMessage(true);
    }, 1000);

    const userRef = doc(db, "users", authedUser.email);
    await updateDoc(userRef, currentMessageData);

    await getResponse(currentMessage);
  }

  if (!messageData) return "Loading";

  return (
    <>
      <header>
        <button onClick={() => handleLogOut()}>Logout</button>
      </header>
      <main>
        <h1>All Messages</h1>
        <div className="chat">
          <div className="chat__sidebar">
            <div className="header">
              <div className="header__top">
                <span>Messages</span>
                <div className="unread__messages">10</div>
              </div>
              <div className="header__bottom">
                <div className="search-wrapper">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  <input type="text" placeholder="Search" />
                </div>
              </div>
            </div>
            <ul className="user-list">
              {messageData.employees.map((user, idx) => {
                return (
                  <li
                    className={`user-list__item ${
                      selectedChat === idx ? "user-list__item--active" : ""
                    }`}
                    key={user.id}
                  >
                    <div
                      className="user-list__item__container"
                      onClick={() => setSelectedChat(idx)}
                    >
                      <div className="item__top">
                        <div className="item__top__left">
                          <div className="unread-indicator"></div>
                          <div className="avatar">
                            <img src={user.avatar} alt="" />
                          </div>
                          <div className="user-details">
                            <span className="user-name">{user.userName}</span>
                            <span className="job-title">{user.jobTitle}</span>
                          </div>
                        </div>
                        <div className="item__top__right">
                          <div className="time-ago">
                            {formatDateIntoTime(
                              getLastMessageProperty(user, "date")
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="item__bottom">
                        {getLastMessageProperty(user, "message")}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="messages">
            <div className="messages__header">
              <div className="avatar">
                <img src={messageData.employees[selectedChat].avatar} alt="" />
              </div>
              <div className="user-details">
                <span className="user-name">
                  {messageData.employees[selectedChat].userName}
                </span>
                <span className="job-title">
                  {messageData.employees[selectedChat].jobTitle}
                </span>
              </div>
              <div className="online-indicator">Online</div>
            </div>
            <div className="messages__body">
              <div className="messages__body__container">
                <div className="inner-container">
                  {sortedMessages !== null &&
                    sortedMessages?.map((messages) => {
                      return (
                        <div key={messages.id}>
                          <div className="message-row message-row--date">
                            <div className="line"></div>
                            <span>{messages.date}</span>
                          </div>
                          {messages.messages.map((message) => {
                            return (
                              <div
                                key={message.id}
                                className={`message-row message-row--other-user ${
                                  message.type === "notLoggedInUser"
                                    ? "message-row--other-user"
                                    : "message-row--self"
                                }`}
                              >
                                <div className="row-left">
                                  <div className="avatar">
                                    <img
                                      src={
                                        messageData.employees[selectedChat]
                                          .avatar
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="row-right">
                                  <div className="row-right__top">
                                    <div className="name">
                                      {message.type === "notLoggedInUser"
                                        ? messageData.employees[selectedChat]
                                            .userName
                                        : authedUser.displayName}
                                    </div>
                                    <div className="time">
                                      {formatDateIntoTime(message.date)}
                                    </div>
                                  </div>
                                  <div className="row-right__bottom">
                                    {message.message.replace("\n\n", "")}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  {showLoadingMessage && (
                    <div className="message-row message-row--other-user message-row--other-user message-row--loading">
                      <div className="row-left">
                        <div className="avatar"></div>
                      </div>
                      <div className="row-right">
                        <div className="row-right__top"></div>
                        <div className="row-right__bottom">
                          <div class="loader">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="messages__footer">
              <div className="footer__container">
                <textarea
                  name=""
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Send a message"
                ></textarea>
                <button onClick={() => submitMessage()}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </>
  );
}
