import Login from "./pages/Login";
import Authed from "./pages/Authed";
import { initializeApp } from "firebase/app";
import { useEffect, useState, createContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseConfig } from "./data/firebaseConfig";

export const AppContext = createContext();
const app = initializeApp(firebaseConfig);

function App() {
  const [authedUser, setAuthedUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthedUser(user);
      } else {
        setAuthedUser(null);
      }
    });
  }, []);

  return (
    <>
      {authedUser ? (
        <AppContext.Provider value={{ app, authedUser }}>
          <Authed />
        </AppContext.Provider>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
