import React, { useContext, useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore/lite";
import Loading from "../components/Loading";
import Messaging from "./Messaging";
import { generateEmployees } from "../data/generateFakeData";
import { AppContext } from "../App";

export default function Authed() {
  const { app, authedUser } = useContext(AppContext);
  const [userReady, setUserReady] = useState(false);
  const db = getFirestore(app);

  useEffect(() => {
    //Check if the user is in the db
    const userData = async () => {
      const docRef = doc(db, "users", authedUser.email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserReady(true);
      } else {
        //Generate  Fake Employees
        const employees = generateEmployees();

        const userObj = {
          id: authedUser.uid,
          name: authedUser.displayName,
          email: authedUser.email,
          employees,
        };

        const usersRef = collection(db, "users");
        await setDoc(doc(usersRef, authedUser.email), userObj);
        setUserReady(true);
      }
    };

    userData();
  }, []);

  return userReady ? <Messaging /> : <Loading />;
}
