import React from "react";

export default function Loading() {
  return (
    <>
      <div className="loading-screen">
        <div className="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </>
  );
}
