import Fakerator from "fakerator";
import { AvatarGenerator } from "random-avatar-generator";
import date from "date-and-time";
import { v4 as uuidv4 } from "uuid";

const jobRoles = [
  {
    title: "Social Medial Expert",
    message:
      "Thank you for giving me the opportunity to join your team. I am excited to begin working with you and I am available at any time to help in any way I can.",
  },
  {
    title: "IT Support",
    message:
      "I am so excited to start this new chapter in my career with you as my boss. Thank you for the opportunity and please know that I am available at any time to help in any way I can.",
  },
  {
    title: "Senior Designer",
    message:
      "I am so grateful for the chance to work with such a talented and accomplished boss. Thank you for hiring me and please know that I am always willing to assist in any way needed.",
  },
  {
    title: "Senior Software Developer",
    message:
      "Thank you for believing in me and offering me this job. I am thrilled to be a part of your team and am always available to help in any way I can.",
  },
  {
    title: "Senior Copy Writer",
    message:
      "I am honored to have been chosen for this position and I want to express my gratitude to you for hiring me. I'm looking forward to starting work and I am always ready to assist in any way needed.",
  },
  {
    title: "Head of Legal",
    message:
      "I am so excited to start this new chapter in my career with you as my boss. Thank you for the opportunity and please know that I am available at any time to help in any way I can.",
  },
];

export function generateEmployees() {
  const fakerator = Fakerator("en-gb");
  const generator = new AvatarGenerator();

  const now = new Date();
  const time = date.format(now, "YYYY-MM-DDTHH:mm:ss");

  const employees = [];
  jobRoles.forEach((role) => {
    employees.push({
      id: uuidv4(),
      userName: fakerator.names.name(),
      jobTitle: role.title,
      avatar: generator.generateRandomAvatar(fakerator.names.name()),
      messages: [
        {
          id: uuidv4(),
          type: "notLoggedInUser",
          message: role.message,
          date: time,
        },
      ],
    });
  });

  return employees;
}
