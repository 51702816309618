import React from "react";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

export default function Login() {
  const handleSignInAttempt = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope([
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ]);

    provider.setCustomParameters({
      hd: "kvadigital.co.uk",
      login_hint: "your.name@kvadigital.co.uk",
    });

    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <button
      onClick={() => handleSignInAttempt()}
      style={{ alignSelf: "center", margin: "auto", cursor: "pointer" }}
    >
      Login With Google
    </button>
  );
}
